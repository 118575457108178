<template>
  <div class="menu-index">
        <navigationBar :routerList="this.$router.history.current.meta.breadcrumb" 
          @buttonClickEmit0="addOrEditMenu" 
          :buttonList="buttonList"/>
        <!-- <div>
            <el-button type="primary" size="small" @click="addOrEditMenu()">新增</el-button>
        </div> -->
        <div class="menu-tree">
        	<el-tree :data="data">
		        <span class="custom-tree-node" slot-scope="{ node, data }">
		          <span>{{ data.name }}</span>
		          <span class="tree-button">
		            <span v-if="data.enable">启用</span><span v-else>停用</span>
		            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click.stop="deleteMenuId(data.id)"></el-button>
		            <el-button type="primary" icon="el-icon-edit" circle size="mini" @click.stop="addOrEditMenu(data)"></el-button>
		          </span>
		        </span>
		    </el-tree>
        </div>

        <el-dialog :title="titleMenu" :visible.sync="dialogFormVisible" width="30%" >
        	<el-form ref="form" :model="form" :rules="rules" label-width="80px">
        		<el-form-item label="菜单名称" prop="name">
        			<el-input v-model="form.name"></el-input>
        		</el-form-item>
        		<el-form-item label="url地址" prop="url">
        			<el-input v-model="form.url"></el-input>
        		</el-form-item>
        		<el-form-item label="父级菜单">
        			<el-select v-model="form.parent_id" placeholder="请选择父级菜单" style="width:100%;" :filterable="true">
        				<el-option label="一级菜单" :value="0"></el-option>
        				<template  v-for="item in data" >
        					<el-option :key="item.value"  :label="'|--'+item.name" :value="item.id"></el-option>
        					<el-option v-for="second in item.children" :key="second.value"  :label="'|----'+second.name" :value="second.id"></el-option>
        				</template>
        			</el-select>
        		</el-form-item>
        		<el-form-item label="排序">
        			<el-input v-model="form.sort"></el-input>
        		</el-form-item>
        		<el-form-item label="图标">
        			<el-input v-model="form.icon"></el-input>
        		</el-form-item>
        		<el-form-item label="是否开启">
        			<el-switch v-model="form.enable" :active-value="1" :inactive-value="0" ></el-switch>
        		</el-form-item>
        	</el-form>
        	<span slot="footer" class="dialog-footer">
        		<el-button size="small" type="primary" @click="submitForm()">确 定</el-button>
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        	</span>
        </el-dialog>
  </div>

  
</template>

<script>
import navigationBar from '@/components/navigationBar'
  import setting from '@/utils/config/index.js'
import {getMenuList,addOrUpdateMenu,deleteMenuId} from '@/api/getUcenterData'
export default {
    data() {
      return {

      	data: [],
      	dialogFormVisible:false,
      	titleMenu:'',
      	form:{},
        buttonList:[
          {
            backgroundColor:'#52D1AF',
            icon:'/static/img/add.png',
            text:'新增',
          }
          
        ],

          rules:{
              password: [
                  { required: true, message: '请输入密码', trigger: 'blur' },
                  { min: 8,max: 25,message: '长度在 8 到 25个字符'},
                  {pattern: /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,25}/, message: '只能输入8-25个,且必须有数字字母特殊字符组合'}
              ]
          },

      }
    },
    components:{
        navigationBar
    },
    created(){
    	this.getMenuList();
    },
    mounted(){
    },
    methods:{
        //菜单列表信息
        getMenuList:function(){
        	getMenuList( ).then((resData)=>{
				console.log(resData.resultCode)
				if(resData.resultCode==1){
					let res= resData.data;
					if(res.length > 0){
						this.data = this.buildTree(res,0);
					}
				}else{
					this.$message({message: resData.resultMsg,type: 'error'});
				}
        		
        	})  
        },
        //树杈数列表
        buildTree:function(arr,parent_id){
        	let resArray=[];
        	for(var i = 0 ; i < arr.length ; i++){
        		if(arr[i].parent_id == parent_id){
        			resArray.push(arr[i]);
        		}
        	}
        	if(resArray.length>0){
        		for(var i = 0 ; i < resArray.length;i++){
        			resArray[i].children = this.buildTree(arr,resArray[i].id);
        		}
        	}
        	return resArray;
        },
        //新增或编辑菜单信息弹出
    	addOrEditMenu:function(data){
    		this.dialogFormVisible = true;
    		if(data == null){
    			this.titleMenu = '新增菜单';
    			this.form = {};
    		}else{
    			this.titleMenu = '编辑菜单';
    			this.form = JSON.parse(JSON.stringify(data));
    		}
    	},
    	//保存菜单信息
    	submitForm:function(){
    		this.$confirm('确认提交吗？', '提示', {}).then(() => {
	    		let para = {
	    			id:this.form.id,
	    			name:this.form.name,
	    			parent_id:this.form.parent_id,
	    			url:this.form.url,
	    			enable:this.form.enable,
	    			sort:this.form.sort,
	    			icon:this.form.icon ,
	    		};
	    		addOrUpdateMenu(para).then((res) => {
	                if (res.resultCode == 1) {
	                    this.$message({message: '编辑或新增成功',type: 'success'});
	                    this.dialogFormVisible=false;
	                } else {
	                   this.$message({message: res.resultMsg ,type: 'error'});
	                }
	                this.getMenuList();
	            });
	    	});
    	},
    	//删除菜单信息
        deleteMenuId:function(id){
        	this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                type: 'warning'
            }).then(() => {
                deleteMenuId({id}).then((res) => {
                    if (res.resultCode == 1) {
                        this.$message({message: '删除成功',type: 'success'});
                        this.dialogFormVisible=false;
                    } else {
                       this.$message({message: res.resultMsg ,type: 'error'});
                    }
                    this.getMenuList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

    }
  }
</script>
<style lang="scss">
.menu-index{
	.menu-tree{
		margin-top:10px;
		.el-tree-node__content{
			height: 40px;
		}
		.el-tree-node {
			border-bottom: 1px solid #ECEEF6;
		}
		.el-tree-node:last-child {
			border-bottom: 0px solid #ECEEF6;
		}
		.custom-tree-node {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 16px;
			padding-right: 8px;
			.tree-button{
				float:right;
				display:inline-block;
				span:first-child{
					margin-right:100px;
				}
			}
		}

   }
   .el-dialog__header {
        background: #E9ECEE;
        padding: 1rem;
    }
    .el-dialog__headerbtn {
        top: 1.25rem;
    }
    .el-dialog__title{
        font-weight: bold;
    }
}
</style>
